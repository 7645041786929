import { useEffect, useState } from 'react';
import style from './Home.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Form from '../../components/Form/Form';
import Claim from '../../components/Claim/Claim';

function Home() {
    const weapons = [
        { id: '0', name: 'axe', damage: '?', image: 'axe', isActive: true },
        { id: '1', name: 'baton', damage: '?', image: 'baton', isActive: true },
        { id: '2', name: 'knife', damage: '?', image: 'knife', isActive: true },
    ];

    const robots = [
        { id: 0, level: 1, model: 'HAND', image: 'hand', isActive: false, hp: '1' },
        { id: 1, level: 2, model: 'Rover', image: 'moon-rover', isActive: false, hp: '1' },
        { id: 2, level: 3, model: 'Rocet', image: 'rocet', isActive: false, hp: '1' },
        { id: 3, level: 4, model: 'Socket', image: 'socket', isActive: false, hp: '1' },
        { id: 4, level: 5, model: 'Vacum', image: 'vacuum', isActive: false, hp: '1' },
        { id: 5, level: 6, model: 'R2-D2', image: 'R2-D2', isActive: false, hp: '1' },
        { id: 6, level: 7, model: 'teapot', image: 'teapot', isActive: false, hp: '1' },
        { id: 7, level: 8, model: 'tracked', image: 'tracked', isActive: false, hp: '1' },
        { id: 8, level: 9, model: 'android', image: 'android', isActive: false, hp: '1' },
        { id: 9, level: 10, model: 'BOSS', image: 'BOSS', isActive: false, hp: '1' },
    ];

    
    const [launchAnimation, setLaunchAnimation] = useState(false);
    const [scroll, setScroll] = useState(false);
    
    const [selectedWeapon, setSelectedWeapons] = useState(weapons);
    const [currentWeapons, setCurrentWeapons] = useState(false);
    
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
    const [showEffect, setShowEffect] = useState(false);
    const [textShowEffect, setTextShowEffect] = useState(null);
    
    const [lineHealth, setLineHealth] = useState(100);
    const [activeRobot, setActiveRobot] = useState(robots[0]);
    const [coin, setCoin] = useState(0);
    
    const [form, setForm] = useState(false);
    const [claim, setClaim] = useState(false);

    useEffect(() => {
        setLaunchAnimation(true);
        setTimeout(() => {
            setScroll(true);
        }, 1000);
    }, []);

    useEffect(() => {
        if (lineHealth === 0) {
            let currentId;
            for (let i = 0; i < robots.length; i++) {
                let robot = robots[i];
                if (activeRobot && activeRobot.id === robot.id && robot.id < robots.length - 1) {
                    currentId = robot.id + 1;
                    setActiveRobot(robots[currentId]);
                    setLineHealth(100);
                    handleCoins();
                    break;
                }
            }
            if (currentId === undefined) {
                setActiveRobot(false);
                setLineHealth(0);
                setClaim(true);
            }
        }
    }, [lineHealth]);

    const handleClickWeapons = (weaponId) => {
        const updatedWeapons = weapons.map((weapon) => {
            if (weapon.id === weaponId) {
                setCurrentWeapons(weapon);
            }
            return {
                ...weapon,
                isActive: weapon.id === weaponId ? true : false,
            };
        });

        setSelectedWeapons(updatedWeapons);
    };

const handleClickAnimation = (event) => {
    if (currentWeapons) {
        if (event.target.id === 'robot') {
            const randomEffect = Math.floor(Math.random() * 20) + 1;
            setTextShowEffect(`-${randomEffect}`);
        } else if (event.target.id === 'shell') {
            setTextShowEffect('Miss');
        } else if (activeRobot === false) {
            setTextShowEffect('VICTORY');
        } else {
            setTextShowEffect('Miss'); 
        }
    } else {
        setTextShowEffect('Choose your weapon');
    }

    const { clientX, clientY } = event;
    setClickPosition({ x: clientX, y: clientY });
    setShowEffect(true);
    console.log(clientX);

    setTimeout(() => {
        setShowEffect(false);
    }, 500);
};



    const handleClickDamage = () => {
        if (currentWeapons) {
            setLineHealth(Math.max(0, lineHealth - (100 / activeRobot.hp)));
        }
    };

    const handleCoins = () => {
        if (coin <= 30) {
            setCoin(coin + Math.floor(Math.random() * 3) + 1)
        }
    }

    const activeForm = () => {
        setForm(true);
        setClaim(false);
    }

    return (
        <>
            {claim && <Claim coin={coin} activeForm={activeForm} />}
            {form && <Form coin={coin} />}
            <div className={style.wrapper}>
                <Header />
                <main style={scroll ? { overflow: 'auto' } : { overflow: 'hidden' }} className={style.main}>
                    <section id='shell' onClick={handleClickAnimation} className={style.robots}>
                        <div className={style.body}>
                            {showEffect && (
                                <div style={window.screen.width > 1000 ? { position: 'fixed', zIndex: '10000', top: clickPosition.y - 100, left: `calc(${clickPosition.x}px - 30%)`, color: '#14fe17', textShadow: '2px 2px 2px #272b23' } : { position: 'fixed', zIndex: '10000', top: clickPosition.y - 50, left: clickPosition.x + 50, color: '#14fe17', textShadow: '2px 2px 2px #272b23' }}>
                                    <span style={{ fontSize: '35px' }}>{textShowEffect}</span>
                                </div>
                            )}
                            {activeRobot ?
                                <div className={style.shell}>
                                    <img id='robot' onClick={handleClickDamage} src={`/image/robots/${activeRobot.image}.gif`} className={style.img} />
                                    <h3 className={`${style.title} ${launchAnimation && `${style.animation__launch__title}`}`}>Model: {activeRobot.model}</h3>
                                </div>
                                : <p className={style.win}>You defeated everyone</p>}
                        </div>
                    </section >
                    <section className={style.indicator}>
                        <div className={style.body}>
                            <div className={`${style.extreme} ${launchAnimation && `${style.animation__launch}`}`}>
                                <p className={style.level}>{activeRobot ? `LEVEL${activeRobot.level}` : 'Victory'}</p><div className={style.money}><img src="/image/coin.svg" alt="coin" className={style.img} /><span className={style.money__text}>{coin}</span></div>
                            </div>
                            <div className={`${style.health} ${launchAnimation && `${style.animation__launch}`}`}>
                                <p>HP</p>
                                <div className={style.shell}>
                                    <div className={style.line} style={{ width: `${lineHealth}%` }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={`${style.weapons} ${launchAnimation && `${style.animation__launch}`}`}>
                        <div className={style.container}>
                            <p className={style.title}>{currentWeapons ? `You chose ${currentWeapons.name}, great taste` : 'Choose your weapon'}</p>
                            <div className={style.body}>
                                {selectedWeapon.map((weapon) => (
                                    <div key={weapon.id}
                                        className={weapon.isActive ? `${style.card} ${style.animation}` : `${style.card} ${style.remove__weapons}`}
                                        onClick={() => handleClickWeapons(weapon.id)}>
                                        <div className={style.weapon}>
                                            <img src={`/image/weapons/${weapon.image}.webp`} alt="" />
                                        </div>
                                        <div className={style.info}>
                                            <img src="/image/crosshair.png" alt="" className={style.svg} />
                                            <p className={style.damage}>{weapon.damage}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section >
                </main >
                <Footer />
            </div >
        </>
    )
}

export default Home