import React, { useState, useEffect } from 'react';
import style from './Form.module.scss';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Функции для работы с куками
const setCookie = (name, value, seconds) => {
    const date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

function Form(props) {
    const [address, setAddress] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fingerprint, setFingerprint] = useState('');

    // Получаем отпечаток браузера при загрузке компонента
    useEffect(() => {
        const getFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            setFingerprint(result.visitorId);  // Устанавливаем уникальный отпечаток браузера
        };
        getFingerprint();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');  // Сбрасываем ошибки

        if (!address) {
            setError('Please enter an address.');
            return;
        }

        setIsSubmitting(true);

        try {
            // Отправляем запрос на сервер для обработки
            const response = await fetch(`https://coin.moi/wallet/miner/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Browser-Fingerprint': fingerprint  // Отправляем отпечаток браузера
                },
                body: JSON.stringify({ address, amount: props.coin }),  // Передаем адрес и сумму
            });

            // Если сервер вернул статус 429 (Too Many Requests), показываем сообщение
            if (response.status === 429) {
                setError('Вы уже пытались получить бонус, попробуйте позже.');
            } else if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            } else {
                const data = await response.json();
                if (data.result) {
                    setTransactionId(data.result.trim());  // Обрабатываем успешный ответ
                }
            }
        } catch (error) {
            console.error('Error sending data:', error);
            setError('Error sending data.');
        } finally {
            setIsSubmitting(false);  // Разблокируем кнопку после завершения запроса
        }
    };

    return (
        <div className={style.shell}>
            {transactionId ? (
                <h2 className={style.transaction}>
                    Transaction ID: 
                    <a 
                        href={`https://explorer.coin.moi/tx/${transactionId}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className={style.idLink}
                    >
                        {transactionId}
                    </a>
                </h2>
            ) : (
                <form className={style.form} onSubmit={handleSubmit}>
                    <input
                        name="address"
                        placeholder="Enter address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <button type="submit" disabled={isSubmitting || !fingerprint}>
                        Send
                    </button>
                    {error && <div className={style.error}>{error}</div>}
                </form>
            )}
        </div>
    );
}

export default Form;
