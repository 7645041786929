import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import GlitchAnimation from '../../components/GlitchAnimation/GlitchAnimation';
import Menu from '../../components/Header/Header';
import style from './Special.module.scss';

function Special() {
    const arraySpecial = [
        { special: 'Coin Name', number: '1', description: 'Moirai (MOI)', isActive: false },
        { special: 'Algorithm', number: '2', description: 'We use the most stable blockchain from Litecoin, which has never failed in work. The well-known Scrypt algorithm', isActive: false },
        { special: 'Total Supply', number: '3', description: 'The maximum number of Moirai is 10 000 000 000. This is the number we consider balanced for infrastructure management.', isActive: false },
        { special: 'Methods of extraction', number: '4', description: 'There are several ways to mine: Play, Mine, Buy.', isActive: false },
        { special: 'Average Block Time ', number: '5', description: '2.5 minutes.', isActive: false },
        { special: 'Block Reward', number: '6', description: 'Initially 5952 MOI, but it halves every 840,000 blocks', isActive: false },
        { special: 'Minimum Transaction Fees', number: '7', description: 'Fees vary depending on network congestion, but the minimum fee is often set at around 0.001 MOI per kilobyte of data.', isActive: false }
    ];

    const [activeArraySpecial, setActiveArraySpecial] = useState(arraySpecial);
    const [currentSpecial, setCurrentSpecial] = useState(null);
    const [launchAnimation, setLaunchAnimation] = useState(false);

    const handleClick = (special) => {
        const newArraySpecial = arraySpecial.map((el) => ({
            ...el, isActive: el.special === special.special ? true : false
        }));
        setActiveArraySpecial(newArraySpecial);
        setCurrentSpecial(special);
    }

    useEffect(() => {
        setLaunchAnimation(true);
        setTimeout(() => {
            setLaunchAnimation(false);
        }, 1100);
    }, []);

    return (
        <>
            <div className={style.wrapper}>
                <GlitchAnimation />
                <Menu />
                <main style={launchAnimation ? { overflow: 'hidden' } : { overflow: 'auto' }}>
                    <section className={style.specials}>
                        <div className={style.container}>
                            <div className={style.body}>
                                <div className={`${style.list} ${launchAnimation && `${style.animation__launch__list}`}`}>
                                    {activeArraySpecial.map((item) => (
                                        <div key={item.name} onClick={() => handleClick(item)} className={style.shell}>
                                            <div>
                                                <p className={item.isActive && style.active}>{item.special}</p>
                                            </div>
                                            <span>{item.number}</span>
                                        </div>
                                    ))}
                                </div>
                                {currentSpecial ?
                                    <div className={`${style.description} ${launchAnimation && `${style.animation__launch__description}`}`}>
                                        <h3 className={style.title}>{currentSpecial.special}</h3>

                                        <p className={style.subtitle}>{currentSpecial.description}</p>
                                    </div>
                                    : <div className={`${style.description} ${launchAnimation && `${style.animation__launch__description}`}`}><h3 className={style.title}>Coin characteristics</h3></div>
                                }
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div >
        </>
    )
}

export default Special;